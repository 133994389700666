import React, { Component } from 'react';
import { StorworkInit, JWT } from 'storwork-stores'
import SimpleMDE from 'react-simplemde-editor'
import _ from 'lodash'
import * as C from 'storwork-components'
import { observer } from 'mobx-react'
import Login from './Login'
import getStore from './Store'
import Comments from './Comments'

const Comment = observer(class _Comment extends React.Component {
  StateInput = C.Input(this)
  state = {
    newMessage: ''
  }
  render () {
    return <form style={{padding: '20px 0 0'}} >
      <this.StateInput path='props.store.object.name'>
        <SimpleMDE options={{
          status: false
        }} />
      </this.StateInput>
      <button style={{marginTop: 10}} className="button is-link" type='submit'>Submit</button>
    </form>
}})


export default Comment
