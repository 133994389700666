import React from 'react'
import {B2CUsers} from 'storwork-stores'

export default class Login extends React.Component {
  state = {
    email: '',
    password: ''
  }
  render () {
    console.log('asdasd')
    return <div color='white' background='linear-gradient( 135deg, #3B2667 10%, #BC78EC 100%)' style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      }}>
      <div margin='300px auto' width='300px'>
        <div font='36px'>
          <div inline weight='300'>Storwork</div>
          <div inline weight='bold'>CMS</div>
        </div>
        <div class="field is-horizontal">
          <input class="input is-success" type='email' name='email' placeholder='Adres email...' value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
          <input class="input is-success" type='password' name='password' placeholder='Hasło...' value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} />
        </div>
        <button class="button is-success" onClick={() => B2CUsers.login(this.state.email, this.state.password)}> Zaloguj </button>
      </div>
    </div>
  }
}
