import { InformationStore } from 'storwork-stores'

let store = null
let interval = null

export default () => {
  if (!store) {
    store = new InformationStore({groupId: 2})
    // setInterval(store.loadRecords, 2000)
  }
  return store
}
