import React, { Component } from 'react';
import { StorworkInit, JWT } from 'storwork-stores'
import _ from 'lodash'
import { observer } from 'mobx-react'
import Login from './Login'
import getStore from './Store'
import Comments from './Comments'

const emptyInformation = {
  content: {}
}

const logout = () => {
  JWT.forget()
  window.location.assign('/')
}

const clear = () => {
  console.log('clear')
}

const react = (store, content) => () => {
  console.log('store')
  console.log(JSON.stringify(store))
  const existingIndex = store.records.findIndex(el => el.content.type === 'reaction')
  const informationIndex = existingIndex === -1 ? store.records.length : existingIndex
  if (existingIndex === -1) store.records.push(_.clone(emptyInformation))
  store.records[informationIndex].content.type = 'reaction'
  store.records[informationIndex].content.content = content
  store.sync()
}

const hasReaction = (store, reaction) => {
  console.log(JSON.stringify(store.records))
  return store.records.some(el => el.content.type === 'reaction' && el.content.content == reaction)
}
const Application = observer(props => <div>
  <a class="button is-link" onClick= {() => logout()}>Logout</a>
  <a class="button is-link" onClick= {() => clear()}>Clear information</a>
    <div class="tile is-ancestor">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success button">
              <p class="title" style={{fontWeight: 'bold'}} onClick={react(props.store, 'cool')}>Cool</p>
            </article>
            <article class="tile is-child notification is-warning button">
              <p class="title" onClick={react(props.store, 'good')}>Good</p>
            </article>
          </div>
          <div class="tile is-parent is-vertical ">
            <article class="tile is-child notification is-danger button">
              <p class="title" onClick={react(props.store, 'toofast')}>Too fast</p>
            </article>
            <article class="tile is-child notification is-info button">
              <p class="title" onClick={react(props.store, 'boting')}>Too slow</p>
            </article>
          </div>
        </div>
    </div>
    <Comments />
</div>)

const ApplicationWithStore = props => <Application store={getStore()} />

class App extends Component {
  render() {
    return <StorworkInit loggedIn={<ApplicationWithStore />} loggedOut={<Login />} />
  }
}

export default App;
